export default defineAppConfig({
    ui: {
        primary: "indigo",
        gray: "cool",
        input: {
            default: {
                size: "md",
            },
        },
        select: {
            default: {
                size: "md",
            },
        },
        modal: {
            container: "items-start sm:items-center",
        },
    },
});
