export const useAccountStore = defineStore("accountStore", () => {
    // State
    const account = ref(null);
    const claimsMap = ref(new Map());
    const paymentsMap = ref(new Map());

    const updateAccountIsActive = ref(false);
    const modalAddClientIsActive = ref(false);
    const modalAddInsuranceIsActive = ref(false);

    // Getters
    const accountId = computed(() => {
        return account.value?.id || null;
    });
    const accountClients = computed(() => {
        return account.value?.clients || [];
    });
    const accountUsers = computed(() => {
        return account.value?.users || [];
    });
    const insurance = computed(() => {
        return account.value?.insurance || [];
    });
    const claims = computed(() => {
        return [...claimsMap.value.values()];
    });
    const payments = computed(() => {
        return [...paymentsMap.value.values()];
    });
    const stripeCustomerId = computed(() => {
        return account.value?.stripe_customer_id || null;
    });
    const stripePaymentMethodId = computed(() => {
        return account.value?.stripe_payment_method_id || null;
    });
    const onboardStatus = computed(() => {
        let onboardStatus = null;
        if (
            account.value?.clients.length > 0 &&
            account.value?.stripe_payment_method_id &&
            account.value?.insurance.length > 0 &&
            account.value?.insurance[0].insurance_company_id &&
            account.value?.insurance[0].plan &&
            account.value?.insurance[0].policy_document_id_front
        ) {
            onboardStatus = "approved";
        } else if (
            account.value?.clients.length > 0 &&
            account.value?.stripe_payment_method_id &&
            account.value?.insurance.length > 0 &&
            account.value?.insurance[0].plan &&
            account.value?.insurance[0].policy_document_id_front &&
            !account.value?.insurance[0].insurance_company_id
        ) {
            onboardStatus = "needs_approval";
        } else {
            onboardStatus = "incomplete";
        }
        return onboardStatus;
    });

    // Actions
    async function fetchAccount(reload?: boolean) {
        console.log("fetchAccount");
        const userStore = useUserStore();
        const accountId = userStore.accountId;
        console.log("fetchAccount accountId: ", userStore.accountId);

        if (account.value && !reload) return;

        const data: any = await $fetchWithToken(
            `/api/v4/account/${accountId}/`
        );
        console.log("fetchAccount data: ", data);
        account.value = data.account;
    }

    async function fetchAccountClaims(reload?: boolean) {
        console.log("fetchAccountClaims reload: ", reload);
        const userStore = useUserStore();
        const accountId = userStore.accountId;
        console.log("fetchAccountClaims accountId: ", accountId);

        if (claimsMap.value.size && !reload) return;

        const data: any[] = await $fetchWithToken(
            `/api/v4/account/${accountId}/claims`
        );
        claimsMap.value.clear();
        data.forEach((claim: any) => {
            claimsMap.value.set(claim.id, claim);
        });
    }

    async function fetchAccountPayments(reload?: boolean) {
        console.log("fetchAccountPayments reload: ", reload);
        const userStore = useUserStore();
        const accountId = userStore.accountId;

        if (paymentsMap.value.size && !reload) return;

        const data: any[] = await $fetchWithToken(
            `/api/v4/account/${accountId}/payments`
        );
        paymentsMap.value.clear();
        data.forEach((payment: any) => {
            paymentsMap.value.set(payment.id, payment);
        });
    }

    async function setAccountDefaultPaymentMethod(id: string) {
        console.log("setAccountDefaultPaymentMethod id: ", id);
        const userStore = useUserStore();
        const accountId = userStore.accountId;

        const payload = {
            stripe_payment_method_id: id,
        };
        const data: any = await $fetchWithToken(
            `/api/v4/account/${accountId}`,
            {
                method: "post",
                body: payload,
            }
        );
        account.value = data;
    }

    function $reset() {
        account.value = null;
        claimsMap.value.clear();
        paymentsMap.value.clear();
    }

    return {
        account,
        accountId,
        updateAccountIsActive,
        modalAddClientIsActive,
        modalAddInsuranceIsActive,

        accountClients,
        accountUsers,
        insurance,
        claims,
        payments,
        stripeCustomerId,
        stripePaymentMethodId,
        onboardStatus,

        fetchAccount,
        fetchAccountClaims,
        fetchAccountPayments,
        setAccountDefaultPaymentMethod,
        $reset,
    };
});
