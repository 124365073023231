import { defineStore, getActivePinia, skipHydrate } from "pinia";

export const useAuthStore = defineStore("authStore", () => {
    // State
    const authStateReady = ref(false);
    const authUser = ref(null);
    const user = ref(null);
    const partner = ref(null);
    const client = ref(null);
    const sbUserId = ref(null);
    const sbUser = ref(null);

    // Getters
    const isAuthenticated = computed(() => {
        return authUser.value ? true : false;
    });
    const userStateReady = computed(() => {
        let userStateReady = false;
        if (authStateReady.value) {
            if (authUser.value && user.value) userStateReady = true;
            else if (authUser.value && sbUser.value) userStateReady = true;
            else if (!authUser.value && !user.value) userStateReady = true;
        }
        return userStateReady;
    });
    const isClient = computed(() => {
        return client.value ? true : false;
    });
    const isPartner = computed(() => {
        return partner.value ? true : false;
    });
    const isAdmin = computed(() => {
        let isAdmin = false;
        if (user.value && user.value.admin_id) isAdmin = true;
        return isAdmin;
    });
    const isUser = computed(() => {
        let isUser = false;
        if (sbUserId.value) isUser = true;
        return isUser;
    });
    const hasAccountAccess = computed(() => {
        return sbUser.value?.account_id ? true : false;
    });
    const hasPartnerAccess = computed(() => {
        return sbUser.value?.partner_id ? true : false;
    });
    const hasAdminAccess = computed(() => {
        return sbUser.value?.role == "admin" ? true : false;
    });

    // Actions
    async function authenticateUser() {
        try {
            if (!authUser.value) {
                throw createError("No authenticated user.");
            }
            const data: any = await $fetchWithToken(`/api/v2/authenticate`, {
                method: "post",
                body: { authUser: authUser.value },
            });
            console.log("data: ", data);
            user.value = data.user;
            partner.value = data.partner;
            client.value = data.client;
            if (data.client) {
                const clientStore = useClientStore();
                clientStore.$patch({ client: data.client });
            }
            if (data.insurance) {
                const insuranceStore = useClientInsuranceStore();
                // insuranceStore.$patch({ insurance: data.insurance });
                insuranceStore.$patch((state) => {
                    state.insurance.set(data.insurance.id, data.insurance);
                });
            }
        } catch (error) {
            // let the form component display the error
            return error;
        } finally {
        }
    }

    async function authenticateSupabaseUser() {
        console.log("authenticateSupabaseUser");
        try {
            if (!authUser.value) {
                throw createError("No authenticated user.");
            }
            const data: any = await $fetchWithToken(`/api/v4/authenticate`, {
                method: "post",
            });
            sbUser.value = data.user;
            const userStore = useUserStore();
            userStore.$patch({ user: data.user });

            if (data.account) {
                const accountStore = useAccountStore();
                accountStore.$patch({ account: data.account });
            }
            if (data.partner) {
                const partnerStore = usePartnerStore();
                partnerStore.$patch({ partner: data.partner });
            }
        } catch (error) {
            // let the form component display the error
            return error;
        } finally {
        }
    }

    async function getUser(uid: string) {
        try {
            const data = await $fetchWithToken("/api/user/" + uid, {
                initialCache: false,
            });
            user.value = data;
        } catch (error) {
            return error;
        }
    }

    function clearAuth() {
        user.value = null;
        partner.value = null;
        client.value = null;
    }

    function $reset() {
        authUser.value = null;
        user.value = null;
        partner.value = null;
        client.value = null;
        sbUserId.value = null;
        sbUser.value = null;
    }

    return {
        authStateReady,
        userStateReady,
        authUser,
        user,
        partner,
        client,
        sbUserId,
        sbUser,

        isClient,
        isPartner,
        isAdmin,
        isUser,
        hasAccountAccess,
        hasPartnerAccess,
        hasAdminAccess,

        isAuthenticated,

        authenticateUser,
        authenticateSupabaseUser,
        getUser,
        clearAuth,
        $reset,
    };
});

// if (import.meta.hot) {
//     import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
// }
